<template>
  <div class="subscription-page">
  <the-header
      v-if="isAuthenticatedUser"
      :showOnSubscription="false"
  />
  <div class="subscription-container">
    <img
        src="../../assets/images/globe-compressed.png"
        alt="subscription"
        class="subscription-container__image"
    />
    <h2 class="subscription-container__title">Subscribe to Glocal</h2>
    <div class="subscription-container__subscription">
      <a-row>
        <a-col :xs="20" :sm="24" :md="20" :lg="20">
          <h2 class="title">
            Your subscription supports our mission to develop a news
            environment where world-wide human and Al collaboration produces
            the most relevant and highest quality news. We're striving for this
            environment to bring great value to your decision-making.
          </h2>
          <p class="description">Select your plan</p>
        </a-col>
        <a-col :xs="20" :sm="24" :md="16" :lg="14" :xl="12">
          <a-row :gutter="[24]">
            <a-col
                v-for="plan in plans"
                :key="plan.stripe_plan_id"
                :xs="24"
                :sm="12"
                :md="12"
                :lg="12"
            >
              <div
                  @click="selectPlan(plan)"
                  class="subscription-card"
                  :class="{ 'select-package': selectedPlan ? plan.stripe_plan_id == selectedPlan.stripe_plan_id : false }"
              >
                <h4 class="subscription-card__pricing">
                  1 {{ plan.name.toLowerCase() }}
                </h4>
                <h2 class="subscription-card__price">
                  ${{ getPricing(plan).actual }}
                  <span v-if="getPricing(plan).discounted_price" class="subscription-card__price--discount-amount">
                    <del class="subscription-card__strike">
                      ${{ getPricing(plan).discounted_price }}
                    </del>
                  </span>
                </h2>
                <h5 class="subscription-card__weekly-price">
                  Only ${{ calculatePerWeekPrice(getPricing(plan).actual, plan.duration_in_days) }} a week.
                </h5>
                <p>Billed {{ plan.name.toLowerCase() }}ly.</p>
                <p>Cancel any time.</p>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <p v-if="error" class="subscription-container__subscription--error">{{ error }}</p>
      <button @click="subscribe(false)" class="ant-btn">
        Proceed to Payment
        <img src="@/assets/icons/payment-icon.svg" alt=" Payment icon"/>
        <div v-if="loader" class="loader">
          <the-loader/>
        </div>
      </button>
      <h2 class="subscription-container__subscription--or">OR</h2>
      <a-button :loading="trialbtnLoader" @click="subscribe(true)">Start a 30-days free trial</a-button>
    </div>
  </div>
  </div>
</template>

<script>
import TheLoader from "../BaseComponents/TheLoader.vue";
import TheHeader from "../../components/layout/ProfilePageHeader.vue";
import StorageService from "@/services/storage.service";

export default {
  mounted() {
    this.subscriptionPackages();
  },
  components: {
    TheLoader,
    TheHeader,
  },
  data() {
    return {
      plans: [],
      selectedPlan: null,
      loader: false,
      trialbtnLoader: false,
      error: "",
    };
  },
  computed: {
    isAuthenticatedUser() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    getUserInformation() {
      return this.$store.getters["profile/userInformation"];
    },
  },
  methods: {
    getPricing(plan) {
      if (plan.discounted_price) {
        return {
          actual: plan.discounted_price,
          discounted_price: plan.price,
        };
      } else {
        return {
          actual: plan.price,
          discounted_price: null,
        };
      }
    },
    calculatePerWeekPrice(planPrice, duration_in_days) {
      return (planPrice / Math.floor(duration_in_days / 7)).toFixed(2);
    },
    async subscriptionPackages() {
      const response = await this.$store.dispatch(
          "auth/getSubscriptionPackages"
      );
      this.plans = response.data;
    },
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    async subscribe(trial) {
      if (!this.selectedPlan) {
        this.error = "Please select any package";
        return false;
      }
      
      this.trialbtnLoader = trial;
      this.loader = !trial;
      
      const payload = {
        price_id: this.selectedPlan.stripe_plan_id,
        email: this.isAuthenticatedUser ? this.getUserInformation.email : StorageService.getData('userEmail'),
        // is_trial: trial
      };

      try {
        const response = await this.$store.dispatch("auth/paymentCheckout", payload);
        if (response.status === 200) {
          window.location.href = response.data.url;
        }
      } catch (err) {
        console.log(err);
      }

      this.loader = false;
      this.trialbtnLoader = false;
    },
  },
};
</script>

<style lang="scss">
.subscription-page {
  .header {
    .mobile-navigation {
      .mobile-navbar {
        background-color: #F5F5F5;
      }
      &__hamburger {
        cursor: pointer;
        .line {
          background-color: $color-black;
        }
      }
    }
  }
}
.subscription-container {
  padding: 8rem 7rem;
  height: 100%;
  background-color: rgb(255 255 255 / 90%);
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: -1;
  }

  &__title {
    color: $color-black;
    font-size: 4rem;
    font-family: $font-secondary-bold;
    margin-bottom: 1rem;
    text-align: center;
    line-height: 5rem;
    position: relative;
  }

  &__subscription {
    margin-top: 2rem;
    position: relative;
    .title {
      color: $color-black;
      font-size: 1.6rem;
      font-family: $font-secondary;
      line-height: 2.4rem;
      text-align: center;
      max-width: 62rem;
      margin: 0 auto;
    }

    .description {
      color: $color-black;
      font-size: 2.2rem;
      font-family: $font-secondary;
      margin-bottom: 0;
      text-align: center;
      opacity: 0.7;
      margin: 8rem 0 4rem;
    }

    .ant-row {
      justify-content: center;
      margin-bottom: 4rem;
    }

    .subscription-card {
      color: $color-black;
      text-align: center;
      border: 0.2rem solid rgb(216, 216, 216);
      margin: 1rem auto;
      padding: 2rem;
      transition: 0.2s;
      max-width: 30rem;
      background-color: #fff;
      cursor: pointer;

      &__pricing {
        color: $color-black;
        font-size: 2.2rem;
        font-family: $font-secondary-bold;
        margin-bottom: 0;
        line-height: normal;
      }

      &__price {
        color: $color-black;
        font-size: 4rem;
        font-family: $font-secondary-bold;
        margin: 3rem 0;
        line-height: normal;

        &--discount-amount {
          font-size: 1.8rem;
          color: rgb(0, 0, 0, 0.5);
        }
      }

      &__weekly-price {
        color: $color-black;
        font-size: 2.2rem;
        font-family: $font-secondary-bold;
        margin-bottom: 1rem;
        line-height: normal;
      }

      p {
        color: $color-black;
        font-size: 1.6rem;
        font-family: $font-secondary;
        margin-bottom: 1rem;
        line-height: normal;
      }

      strike {
        text-decoration-thickness: 2px;
        text-decoration-color: rgb(0, 0, 0, 0.3);
      }
    }

    .subscription-card:hover {
      border: 0.24rem solid $color-primary;
      transform: scale(1.05);
    }

    .select-package {
      transform: scale(1.05);
      border: 0.24rem solid $color-primary;
    }
    &--or {
      margin: 2rem auto;
      display: flex;
      width: fit-content;
      width: 28rem;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: relative;
      color: $color-black;
      font-size: 2rem;
      font-family: $font-secondary;
      opacity: 0.6;
      &::before, &::after {
        position: absolute;
        content: '';
        height: 0.24rem;
        width: 41%;
        background-color: rgba(0, 0, 0, 0.329);
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }

    .ant-btn {
      color: $color-white;
      font-size: 2rem;
      font-family: $font-secondary;
      background-color: $color-primary;
      border: none;
      margin: 0 auto ;
      width: 28rem;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      &:hover,
      &:active,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }

      img {
        width: 3rem;
        margin-left: 1rem;
      }

      .loader {
        line-height: normal;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffff94;
        cursor: auto;

        .ant-spin {
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          .anticon {
            font-size: 3rem !important;

            .anticon-spin {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    &--error {
      color: red;
      font-size: 1.6rem;
      font-family: $font-secondary;
      margin: 2rem auto;
      text-align: center;
    }
  }
}
</style>
